var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  }, [_vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-row-fluid text-center"
  }, [_c('h1', {
    staticClass: "error-title font-weight-boldest text-white mb-12",
    staticStyle: {
      "margin-top": "12rem"
    }
  }, [_vm._v(" Oops... ")]), _c('p', {
    staticClass: "display-4 font-weight-bold text-white"
  }, [_vm._v(" Looks like something went wrong. "), _c('br'), _vm._v("We're working on it "), _c('br')])]);

}]

export { render, staticRenderFns }